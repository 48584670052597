<template>
  <div class="ma-4">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Timeclock Action Log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn exact @click="$router.go(-1)">Go Back</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete v-model="filter.timeclocks" :items="timeclockList" label="Timeclock" item-value="_id" item-text="shortName" multiple outlined clearable @change="loadLogs()" @click:clear="loadLogs()" />
          </v-col>
          <v-col>
            <v-text-field v-model="filter.name" label="Name/Prox Search" outlined @change="loadLogs()" />
          </v-col>
          <v-col>
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" :value="dateRangeText" label="Date Range" hint="Select both start and end dates (can be the same)" persistent-hint readonly outlined clearable @click:clear="clearDates" />
              </template>
              <v-date-picker v-model="filter.dates" :max="yearMonDayFormat(new Date())" no-title scrollable range @change="loadLogs()" />
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table :items="logEntries" :headers="headers" :server-items-length="itemTotal" @update:options="loadLogs">
        <template v-slot:item.timestamp="{ item }">{{ stringFormatDate(item.timestamp) }}</template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { stringFormatDate, yearMonDayFormat } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const logServ = root.$feathers.service('timeclock/log')

    const filter = computed({
      get: () => root.$store.state['work-study'].timeclockFilter,
      set: (val) => root.$store.commit('work-study/setTimeclockFilter', val)
    })
    const dateRangeText = computed(() => {
      if (filter.value.dates.length > 0) {
        return filter.value.dates.join(' ~ ')
      }
    })

    const tableOptions = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['timestamp'],
      sortDesc: [false]
    })

    const timeclockList = computed(() => root.$store.state['work-study'].timeclocks)

    const headers = ref([
      { text: 'Timestamp', value: 'timestamp' },
      { text: 'Timeclock', value: 'shortName' },
      { text: 'Action', value: 'action' },
      { text: 'Name/Prox', value: 'name' },
      { text: 'Result', value: 'result' },
      { text: 'Data', value: 'data' }
    ])

    const logEntries = ref([])
    const itemTotal = ref(0)
    const loadingLogs = ref(false)
    async function loadLogs (options) {
      loadingLogs.value = true
      if (options) {
        for (let l in tableOptions.value) {
          if (l in options) tableOptions.value[l] = options[l]
        }
      }
      const { page, itemsPerPage, sortBy, sortDesc } = tableOptions.value
      const query = {
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }
      const { timeclocks, name, dates } = filter.value
      if (timeclocks.length > 0) {
        query.timeclockId = { $in: timeclocks }
      }
      if (dates != null && dates.length > 0) {
        if (dates[1] < dates[0]) {
          dates.reverse()
        }
        query.timestamp = { $gte: dates[0] + ' 00:00', $lte: dates[1] + ' 23:59:59' }
      }
      if (name != null && name !== '') {
        query.name = { $regex: name, $options: 'i' }
      }
      for (let i = 0; i < sortBy.length; i++) {
        query.$sort[sortBy[i]] = sortDesc[i] ? 1 : -1
      }
      if (sortBy.length === 0) {
        query.$sort['timestamp'] = -1
      }
      const { total, data } = await logServ.find({ query })
      itemTotal.value = total
      logEntries.value = data
      loadingLogs.value = false
    }

    watch(filter, () => {
      loadLogs()
    })

    onMounted(async () => {
      const isAdmin = root.$store.getters.hasRole(['Technology Services', 'HR', 'Fin Aid', 'Center for Calling and Career'])
      if (!isAdmin) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have permission to access the timeclock logs' })
        root.$router.push('/scotswork/timeclock')
      }
      loadLogs()
      if (timeclockList.value.length === 0) {
        root.$store.dispatch('work-study/loadTimeclocks')
      }
    })

    function clearDates () {
      filter.value.dates = []
      loadLogs()
    }

    return {
      user,
      filter,
      dateRangeText,
      tableOptions,
      timeclockList,
      headers,
      logEntries,
      itemTotal,
      loadingLogs,
      loadLogs,
      clearDates,
      yearMonDayFormat,
      stringFormatDate
    }
  }
}
</script>
